* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

body {
  font-family: 'Inter var', sans-serif;
  color: white;
  background: linear-gradient(to top, #000000, #000000, #000000);
  user-select: none;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  color: #606060;

}

.stack {
  padding: 0 24px;
}

.bg {
  background: linear-gradient(to top, #0a0a0a, #181a1a, #1d1d1f);
}

.notready {
  color: #606060;
}

.clicked {
  pointer-events: none;
  opacity: 0;
}

a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: inherit;
}

.content {
  padding: 24px;
  transform: translate3d(10%, 0, 0);
  background: rgba(0,0,0,.5);
  color: white;
  border-radius: 8px;
  width: 300px;
  position: relative;
  left: -100px;
}
.content h3 {
  padding-top: 0;
  margin-top: 0;
}
.content .close-icon {
  position: absolute;
  top: 12px;
  right: 18px;
  font-weight: bold;
}


.controls {
  position: absolute;
  right: 0;
  top: 0;
  padding: 24px;
  color: white;
  display: flex;
  align-items: flex-end;
  gap: 12px;
}
.controls .btn {
  background: rgba(255,255,255,.05);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.controls .btn:hover {
  background: rgba(255,255,255,.5);
  color: #000000;
}
.controls .btn {
  line-height: 1 !important;
}
.controls .btn a{
  padding: 0px;
  margin: 0px;
  line-height: 1 !important;
}

.svg-icon {
  width: 24px;
  height: 24px;
  line-height: 1 !important;
}


/*intro page*/
.mainImage {
  position: relative;
  padding: 0px;
  margin-top: 24px;
  height: 230px;
}
.mainImage .mainFrame {

}
.mainImage .PImage {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
}
.stack h1 {
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
}
.stack h3 {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 480px) {
  .stack {
    width: 600px;
  }
  .mainImage {
    height: 360px;
  }
}

.introText {
  position: absolute;
  width: 400px;
  left: -200px;
  top: -100px;
  padding: 24px;
  transform: translate3d(10%, 0, 0);
  background: rgba(0,0,0,.5);
  color: white;
  border-radius: 8px;
}

.mantine-Modal-inner {
  padding: 48px 24px;
}